import SubscriptionModel from "@/models/Subscription";
import axios from "axios";
//import moment from 'moment';
import { Module } from "vuex";
import { RootStore } from "..";

interface Invoice {
  id: number;
  subscriptionId: number;
  created: string;
  lastProcessed: string;
  status: string;
  feeTaken: string;
}

interface Subscription {
  id: number;
  created: string;
  updated: string;
  active: string;
  type: string;
  subscriptionFee: string;
  subscriptionProvider: string;
  userId: number;
  email?: string;
  name?: string;
  tenant?: string;
}

interface SubscriptionsState {
  invoices: Invoice[]; //Admin page
  subscriptions: Subscription[]; //Admin page
  userList: [];
  myInvoices: Invoice[]; //user page
  mySubscriptions: Subscription[]; //user page.
}

interface SetSubscriptionsModel {
  users: {
    id: number;
    email: string;
    name: string;
    tenant: string;
  }[];
  subscriptions: Subscription[];
  invoices: Invoice[];
}

const subscriptionsModule: Module<SubscriptionsState, RootStore> = {
  state: {
    invoices: [],
    subscriptions: [],
    userList: [],
    myInvoices: [],
    mySubscriptions: [],
  },
  mutations: {
    SetMySubscriptions(state, list) {
      state.mySubscriptions = list;
    },
    SetMyInvoices(state, list) {
      state.myInvoices = list;
    },
    SetSubscriptions(state, list: SetSubscriptionsModel) {
      for (let i = 0; i < list.subscriptions.length; i++) {
        const idx = list.users.findIndex(
          (x) => x.id == list.subscriptions[i].userId
        );
        if (idx != -1) {
          list.subscriptions[i].email = list.users[idx].email;
          list.subscriptions[i].name = list.users[idx].name;
          list.subscriptions[i].tenant = list.users[idx].tenant;
        }
      }

      state.subscriptions = list.subscriptions;
      state.invoices = list.invoices;
      //state.userList = list.users;
    },
    SetInvoices(state, list) {
      state.invoices = list;
    },
    SetMyActiveSubscriptionInactive(state, sub) {
      const idx = state.mySubscriptions.findIndex((x) => x.id == sub.id);
      if (idx != -1) {
        Object.assign(state.mySubscriptions[idx], sub);
      }
    },
    SetActiveSubscriptionInactive(state, sub) {
      const idx = state.subscriptions.findIndex((x) => x.id == sub.id);
      if (idx != -1) {
        Object.assign(state.subscriptions[idx], sub);
      }
    },
    AddSubscription(state, obj) {
      state.mySubscriptions.push(obj.subscription);
    },
  },
  getters: {},
  actions: {
    async GetMySubscriptions({ commit, rootGetters, dispatch }) {
      //User Specific
      dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };
        axios
          .get("/api/subscription/me", config)
          .then((response) => commit("SetMySubscriptions", response.data));
      });
    },
    async GetMyInvoices({ commit, rootGetters, dispatch }) {
      //User Specific
      dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };
        axios
          .get("/api/subscription/invoices/list/me", config)
          .then((response) => commit("SetMyInvoices", response.data));
      });
    },
    async GetMyInvoicesFromSubscription(
      { commit, rootGetters, dispatch },
      subscriptionId
    ) {
      //User Specific
      dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };
        axios
          .get("invoices/list/" + subscriptionId, config)
          .then((response) => commit("setMe", response.data.user));
      });
    },
    async GetSubscriptions({ commit, rootGetters, dispatch }) {
      //Admin Specific
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };

      axios.get("/api/subscription/list", config).then((response) => {
        commit("SetSubscriptions", response.data);
      });
    },
    async GetInvoices({ commit, rootGetters, dispatch }) {
      //Admin specific
      dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };
        axios
          .get("/api/subscription/invoices/list", config)
          .then((response) => commit("SetInvoices", response.data));
      });
    },
    async CreateSubscription({ commit, rootGetters, dispatch }) {
      dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
            "Content-Type": "application/json",
          },
        };
        const subscription = {
          Type: "Premium",
          SubscriptionProvider: "Quickpay",
        };

        const data = JSON.stringify(subscription);

        return new Promise((resolve) => {
          axios
            .post("/api/subscription/create", data, config)
            .then((response) => {
              //commit("AddSubscription", { subscription: response.data.newSubscription});
              window.location.href = response.data.link;

              //commit('addExercise', response.data);
              commit(
                "setAlertSuccessSnack",
                "Abonnementet er oprettet korrekt."
              );
              resolve(true);
            })
            .catch((err) => {
              commit("setAlertErrorSnack", err);
            });
        });

        //axios.post('/api/subscription/create', config).then(response => (commit('SetInvoices', response.data)));
      });
    },
    async CancelSubscription_User({ commit, rootGetters, dispatch }) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.delete(
        "/api/subscription/me/cancel/",
        config
      );
      if (response.status == 200) {
        if (response.data != null) {
          commit("SetMyActiveSubscriptionInactive", response.data);
          await dispatch("GetSubscriptions");
          commit("setAlertSuccessSnack", "Abonnementet er aflyst korrekt.");
        } else {
          commit(
            "setAlertErrorSnack",
            "Noget gik galt internt i API/DB, prøv igen."
          );
        }
      } else {
        commit(
          "setAlertErrorSnack",
          "Noget gik galt internt i API/DB, prøv igen."
        );
      }
    },
    CancelSubscription_Admin(
      { commit, rootGetters, dispatch },
      obj: SubscriptionModel
    ) {
      return dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };
        return axios
          .delete("/api/subscription/cancel/" + obj.id, config)
          .then(async (response) => {
            if (response.status != 200 || response.data == null) {
              console.error(response);
              commit(
                "setAlertErrorSnack",
                "Noget gik galt internt i API/DB, prøv igen."
              );
            } else {
              //commit('SetActiveSubscriptionInactive', response.data);
              commit("setAlertSuccessSnack", "Abonnementet er aflyst korrekt.");
              await dispatch("GetSubscriptions");
            }
          });
      });
    },
  },
};

export default subscriptionsModule;
