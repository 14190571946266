import axios from "axios";
import { Module } from "vuex";
import { RootStore } from "..";

interface TenantsState {
  current: {
    name: string;
  } | null;
  tenants: { id: number; name: string }[];
}

const tenantsModule: Module<TenantsState, RootStore> = {
  state: {
    current: null,
    tenants: [],
  },
  mutations: {
    setCurrentTenant(state, tenant) {
      state.current = {
        name: tenant.name,
      };
    },
    setTenants(state, tenants) {
      state.tenants = tenants;
    },
  },
  actions: {
    async getCurrentTenant({ commit, dispatch, rootGetters }) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.get("/api/tenants/me", config);
      await commit("setCurrentTenant", response.data);
    },
    async getAllTenants({ commit, dispatch, rootGetters }) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.get("/api/tenants", config);
      commit("setTenants", response.data);
    },
  },
};

export default tenantsModule;
