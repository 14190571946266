<template>
  <v-card tile :loading="loading" :loader-height="12">
    <v-form ref="form" v-model="valid">
      <v-card class="title-container" color="primary">
        <v-card-title v-if="isNewCourse">
          <span class="headline">Opret Online kursus</span>
        </v-card-title>
        <v-card-title v-else>
          <span class="headline">Rediger Online kursus</span>
        </v-card-title>
        <div style="flex: 1 1 auto"></div>
        <div style="display: flex; align-items: center; margin-right: 10px">
          <v-icon
            style="color: white; cursor: pointer; width: 40px; height: 40px"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </div>
      </v-card>

      <div class="saveBtnContainer" @click="save">
        <div v-if="isNewCourse">OPRET KURSUS</div>
        <div v-else>GEM ÆNDRINGER</div>
      </div>

      <v-card-text>
        <v-container
          style="
            margin-bottom: 40px;
            max-width: none;
            padding-left: 35px;
            padding-right: 35px;
          "
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              style="padding-left: 0; margin-left: -10px"
            >
              <v-select
                v-model="editedCourse.tenantId"
                label="Klinik"
                item-value="id"
                item-text="name"
                :items="tenants"
              />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: 20px">
            <v-col cols="12" sm="6" md="4" style="padding-left: 0">
              <v-text-field
                v-model="editedCourse.name"
                label="Titel"
                hint="Indtast venligst en kursus titel"
                :rules="[() => !!editedCourse.name || 'This field is required']"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="5" class="description-container">
              <v-textarea
                v-model="editedCourse.description"
                auto-grow
                clearable
                clearable-icon="cancel"
                label="Beskrivelse"
                hint="Indtast venligst en beskrivelse"
                outlined
                rows="10"
                style="border-radius: 0"
                :rules="[
                  () =>
                    !!editedCourse.description ||
                    'Kurset mangler en beskrivelse',
                ]"
                required
              />
            </v-col>
            <v-col md="7">
              <v-row>
                <v-col md="6" style="padding-right: 30px">
                  <v-row style="justify-content: center">
                    <v-menu
                      v-model="startDateMenu"
                      min-width="auto"
                      offset-y
                      transition="scale-transition"
                      :close-on-content-click="false"
                      :nudge-right="40"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedCourseStartDateFormatted"
                          class="text-field-right"
                          label="Startdato"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="editedCourse.startDate"
                        @input="startDateMenu = false"
                      />
                    </v-menu>
                  </v-row>
                  <v-row style="justify-content: center">
                    <v-text-field
                      v-model="editedCourse.videoInterval"
                      class="numberInput text-field-right"
                      suffix="dage"
                      label="Dages interval imellem hver video"
                      type="number"
                      min="0"
                      :rules="[
                        () =>
                          isInt(editedCourse.videoInterval) ||
                          'This field is required',
                      ]"
                      required
                    />
                    <div>
                      OBS. Deltagere der kommer på listen efter startdato får
                      adgang til alle videoer som resten har fået på en gang.
                    </div>
                  </v-row>
                  <v-row style="justify-content: center">
                    <v-text-field
                      v-model="editedCourse.priority"
                      class="numberInput text-field-right"
                      label="Prioritet"
                      type="number"
                      style="margin-top: 15px"
                    />
                  </v-row>
                </v-col>
                <v-col md="6" style="padding-left: 30px">
                  <v-row class="course-availability-row">
                    <div class="course-availability-header">
                      Er dit kursus klar til udgivelse i app?
                    </div>
                    <div class="course-availability-content-container">
                      <div
                        class="course-availability-text-container course-availability-text-left"
                      >
                        <p class="course-availability-text">
                          Nej<br />(Kladde)
                        </p>
                      </div>
                      <div class="course-availability-switch-container">
                        <v-switch
                          v-model="editedCourse.isPublished"
                          :disabled="!enableToggleBtns"
                        />
                      </div>
                      <div
                        class="course-availability-text-container course-availability-text-right"
                      >
                        <p class="course-availability-text">Ja<br />(Udgiv)</p>
                      </div>
                    </div>
                  </v-row>
                  <v-row class="course-availability-row">
                    <div class="course-availability-header">
                      Er dit kursus til salg for alle andre?
                    </div>
                    <div class="course-availability-content-container">
                      <div
                        class="course-availability-text-container course-availability-text-left"
                      >
                        <p class="course-availability-text">
                          Nej<br />(Begrænset)
                        </p>
                      </div>
                      <div class="course-availability-switch-container">
                        <v-switch
                          v-model="editedCourse.isPublic"
                          :disabled="!enableToggleBtns"
                        />
                      </div>
                      <div
                        class="course-availability-text-container course-availability-text-right"
                      >
                        <p class="course-availability-text">
                          Ja<br />(Alle kan se og købe)
                        </p>
                      </div>
                    </div>
                  </v-row>
                  <v-row class="course-availability-row">
                    <div class="course-availability-header">
                      Er dit kursus gratis tilgængelig for alle?
                    </div>
                    <div class="course-availability-content-container">
                      <div
                        class="course-availability-text-container course-availability-text-left"
                      >
                        <p class="course-availability-text">
                          Nej<br />(Kræver betaling)
                        </p>
                      </div>
                      <div class="course-availability-switch-container">
                        <v-switch
                          v-model="editedCourse.isFree"
                          :disabled="!enableToggleBtns"
                        />
                      </div>
                      <div
                        class="course-availability-text-container course-availability-text-right"
                      >
                        <p class="course-availability-text">
                          Ja<br />(Det er gratis)
                        </p>
                      </div>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Content -->
          <v-row>
            <v-col>
              <!-- Exercises -->
              <order-filter-table
                targetTitle="Videoer i dette kursus"
                targetSearchPlaceholder="Søg i videoer i dette kursus"
                :targetColumns="headers_exercises"
                :targetItems="editedCourse.exercises"
                @removeFromTarget="removeExercise"
                sourceTitle="Alle videoer"
                sourceSearchPlaceholder="Søg i alle videoer"
                :sourceColumns="headers_allExercises"
                :sourceItems="availableExercises"
                @addToTarget="addExercise"
                :showTargetEditColumn="true"
                @editRow="editVideo"
              />

              <!-- \ Exercises -->
              <!-- Groups -->
              <order-filter-table
                v-if="showAvailableGroupsAndUsers"
                targetTitle="Grupper på dette kursus"
                targetSearchPlaceholder="Søg i grupper på dette kursus"
                :targetColumns="headers_groups"
                :targetItems="editedCourse.groupings"
                @removeFromTarget="removeGrouping"
                sourceTitle="Alle grupper"
                sourceSearchPlaceholder="Søg i alle grupper"
                :sourceColumns="headers_allGroups"
                :sourceItems="availableGroupings"
                @addToTarget="addGrouping"
              />
              <!-- \ Groups -->
              <!-- Paying Groups -->
              <order-filter-table
                v-if="showPayingGroupsAndUsers"
                targetTitle="Betalende grupper på dette kursus"
                targetSearchPlaceholder="Søg i betalende grupper"
                :targetColumns="headers_groups"
                :targetItems="editedCourse.payingGroupings"
                @removeFromTarget="removePayingGrouping"
                sourceTitle="Tilføjede grupper"
                sourceSearchPlaceholder="Søg i tilføjede grupper"
                :sourceColumns="headers_allGroups"
                :sourceItems="availablePayingGroupings"
                @addToTarget="addPayingGrouping"
              />
              <!-- \ Paying Groups -->
              <!-- Users -->
              <order-filter-table
                v-if="showAvailableGroupsAndUsers"
                targetTitle="Brugere på dette kursus"
                targetSearchPlaceholder="Søg i brugere på dette kursus"
                :targetColumns="headers_users"
                :targetItems="editedCourse.users"
                @removeFromTarget="removeUser"
                sourceTitle="Alle brugere"
                sourceSearchPlaceholder="Søg i alle brugere"
                :sourceColumns="headers_allUsers"
                :sourceItems="availableUsers"
                @addToTarget="addUser"
                :showTargetEditColumn="true"
                @editRow="editUser"
              />
              <!-- \ Users -->
              <!-- Paying Users -->
              <order-filter-table
                v-if="showPayingGroupsAndUsers"
                targetTitle="Betalende brugere på dette kursus"
                targetSearchPlaceholder="Søg i betalende brugere"
                :targetColumns="headers_users"
                :targetItems="editedCourse.payingUsers"
                @removeFromTarget="removePayingUser"
                sourceTitle="Tilføjede brugere"
                sourceSearchPlaceholder="Søg i tilføjede brugere"
                :sourceColumns="headers_allUsers"
                :sourceItems="availablePayingUsers"
                @addToTarget="addPayingUser"
                :showTargetEditColumn="true"
                @editRow="editUser"
              />
              <!-- \ Paying Users -->

              <div v-if="userDialog">
                <v-dialog v-model="userDialog" scrollable max-width="1500px">
                  <UserForm
                    formTitle="Rediger Bruger"
                    :_editedBase="editedUser"
                    :editedIndex="0"
                    @close="userDialog = false"
                  />
                </v-dialog>
              </div>
              <v-dialog
                v-model="videoDialog"
                max-width="1500px"
                :no-click-animation="true"
              >
                <CourseMaterialForm
                  :formTitle="'Rediger kursusvideo'"
                  :_baseEditedItem="editedVideo"
                  @close="
                    videoDialog = false;
                    editedVideo = {};
                  "
                  v-if="videoDialog"
                />
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import CourseMaterialForm from "@/components/CourseMaterialForm";
import OrderFilterTable from "@/components/OrderFilterTable.vue";
import UserForm from "@/components/UserForm";
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "OnlineCourseForm",
  components: {
    OrderFilterTable,
    UserForm,
    CourseMaterialForm,
  },
  props: {
    course: {
      type: Object,
      required: false,
      default: null,
    },
    duplicating: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: (instance) => ({
    allGroupings: [],
    allPayingGroups: [],
    allPayingUsers: [],
    allUsers: [],
    editedCourse: {
      id: null,

      name: "",
      description: "",
      exercises: [],

      priority: null,
      startDate: moment.utc(new Date()).local().format("YYYY-MM-DD"),
      videoInterval: null,

      groupings: [],
      payingGroupings: [],

      users: [],
      payingUsers: [],

      isFree: false,
      isPublic: false,
      isPublished: false,

      tenantId: null,
    },

    headers: [
      { text: "Prioritet", align: "start", value: "priority", sortable: true },
      { text: "Navn", value: "name", sortable: true },
      { text: "Grupper på kursus", value: "groupings", sortable: false },
      { text: "Oprettet", value: "created", sortable: false },
      { text: "Brugere", value: "userCount", sortable: true },
      { text: "Grupper", value: "groupingCount", sortable: true },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_allExercises: [
      {
        text: "Video ID",
        align: "start",
        value: "id",
        sortable: true,
        width: "100px",
      },
      { text: "Navn", value: "title", sortable: true },
    ],
    headers_allGroups: [
      {
        text: "Gruppe ID",
        align: "start",
        value: "groupID",
        sortable: true,
        width: "100px",
      },
      { text: "Navn", value: "name", sortable: true },
    ],
    headers_allUsers: [
      {
        text: "Bruger ID",
        align: "start",
        value: "id",
        sortable: true,
        width: "100px",
      },
      { text: "Navn", value: "name", sortable: true },
      { text: "Email", value: "email", sortable: true },
    ],
    headers_exercises: [{ text: "Navn", value: "title", sortable: true }],
    headers_groups: [
      { text: "Navn", align: "start", value: "name", sortable: true },
    ],
    headers_users: [
      { text: "Navn", align: "start", value: "name", sortable: true },
      { text: "Email", align: "start", value: "email", sortable: true },
    ],
    startDateMenu: false,
    valid: false,
    userDialog: false,
    videoDialog: false,
    editedVideo: {},
    editedUser: {},
  }),
  computed: {
    ...mapState({
      exercises: (state) => state.exercises.exercises,
      courses: (state) => state.courses.courses,
      groupings: (state) => state.groupings.groupings,
      loggedInUser: (state) => state.auth.user,
      loading: (state) => state.loading,
      loggedIn: (state) => !!state.auth.token,
      users: (state) => state.users.users,
      tenants: (state) => state.tenants.tenants,
      currentTenant: (state) => state.tenants.current,
    }),

    isNewCourse() {
      return (
        this.duplicating ||
        !this.course ||
        !this.course.id ||
        this.course.id < 0
      );
    },

    availableExercises() {
      return this.exercises.filter(
        (e) => !this.editedCourse.exercises.find((ce) => ce.id === e.id)
      );
    },

    availableGroupings() {
      return this.groupings.filter(
        (g) => !this.editedCourse.groupings.find((cg) => cg.id === g.id)
      );
    },
    availablePayingGroupings() {
      if (!this.showAvailableGroupsAndUsers && this.showPayingGroupsAndUsers) {
        return this.groupings.filter(
          (g) =>
            !this.editedCourse.payingGroupings.find((cpg) => cpg.id === g.id)
        );
      }
      return this.editedCourse.groupings.filter(
        (cg) =>
          !this.editedCourse.payingGroupings.find((cpg) => cpg.id === cg.id)
      );
    },

    availableUsers() {
      return this.users.filter(
        (u) => !this.editedCourse.users.find((cu) => cu.id === u.id)
      );
    },
    availablePayingUsers() {
      if (!this.showAvailableGroupsAndUsers && this.showPayingGroupsAndUsers) {
        return this.users.filter(
          (u) => !this.editedCourse.payingUsers.find((cpu) => cpu.id === u.id)
        );
      }
      return this.editedCourse.users.filter(
        (cu) => !this.editedCourse.payingUsers.find((cpu) => cpu.id === cu.id)
      );
    },

    editedCourseStartDateFormatted() {
      return this.editedCourse
        ? moment(new Date(this.editedCourse.startDate)).format("DD-MM-YYYY")
        : "";
    },
    enableToggleBtns() {
      return true;
      // disabled because Mathias said so
      // if (!this.isEditCourse) {
      //     return true;
      // }

      // if (!this.editedCourseIsPublished) {
      //     return true;
      // }

      // return false;
    },
    // editedCourseIsPublished() {
    //     if(this.editedCourse.id == null){
    //         return false;
    //     }
    //     return this.courses.find(x => x.id == this.editedCourse.id)?.isPublished;
    // },
    showPayingGroupsAndUsers() {
      if (this.editedCourse.isFree) {
        return false;
      }
      return true;
    },
    showAvailableGroupsAndUsers() {
      if (this.editedCourse.isPublic) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    if (!this.loggedIn) {
      this.$router.push({
        path: "/",
      });
    } else {
      await this.initialize();
    }
  },
  methods: {
    async initialize() {
      this.$store.commit("setLoading", true);
      this.$store.dispatch("getAllTenants");
      await Promise.all([
        this.$store.dispatch("getAllCourses"),
        this.$store.dispatch("getAllExercises"),
        this.$store.dispatch("getAllGroupings"),
        this.$store.dispatch("getAllUsers"),
      ]);

      if (this.course) {
        this.editedCourse = await this.parseCourse(this.course);
        console.log("Course: ", this.editedCourse);
      } else {
        console.log("No course");
      }
      this.$store.commit("setLoading", false);
    },
    async parseCourse(course) {
      const courseId = course.id;
      const courseExercises = await this.$store.dispatch(
        "getCourseExercises",
        courseId
      );
      const courseUsers = await this.$store.dispatch(
        "getCourseUsers",
        courseId
      );

      const exercises = courseExercises
        .map((ce) => this.exercises.find((e) => ce.id === e.id))
        .filter((ce) => !!ce);
      const users = courseUsers
        .map((cu) => this.users.find((u) => cu.id === u.id))
        .filter((cu) => !!cu);
      const groupings = course.groupings
        .map((g) => this.groupings.find((cg) => cg.id === g.id))
        .filter((cg) => !!cg);

      const payingGroupings = groupings.filter((g) =>
        course.groupings.find((cg) => cg.id === g.id && cg.hasPaid)
      );
      const payingUsers = users.filter((u) =>
        courseUsers.find((cu) => cu.id === u.id && cu.hasPaid)
      );

      return {
        id: this.duplicating ? null : course.id,
        name: course.name,
        description: course.description,
        startDate: course.startDate,
        videoInterval: course.videoInterval,
        priority: course.priority,
        isFree: course.isFree,
        isPublic: course.isPublic,
        isPublished: course.isPublished,

        exercises: exercises,
        groupings: groupings,
        users: users,

        payingGroupings: payingGroupings,
        payingUsers: payingUsers,

        tenantId: course.tenantId,
      };
    },

    removeExercise(row) {
      this.editedCourse.exercises = this.editedCourse.exercises.filter(
        (r) => r.id !== row.id
      );
    },
    addExercise(row) {
      this.editedCourse.exercises = [...this.editedCourse.exercises, row];
    },

    removeGrouping(row) {
      if (this.editedCourse.payingGroupings.find((pg) => pg.id === row.id)) {
        if (
          !confirm(
            "Du er ved at fjerne en betalende gruppe fra kurset. Vil du fortsætte?"
          )
        ) {
          return false;
        }
      }
      this.editedCourse.groupings = this.editedCourse.groupings.filter(
        (r) => r.id !== row.id
      );
      this.removePayingGrouping(row);
    },
    addGrouping(row) {
      this.editedCourse.groupings = [...this.editedCourse.groupings, row];
    },
    removePayingGrouping(row) {
      this.editedCourse.payingGroupings =
        this.editedCourse.payingGroupings.filter((r) => r.id !== row.id);
      if (!this.showAvailableGroupsAndUsers && this.showPayingGroupsAndUsers) {
        if (this.editedCourse.groupings.some((g) => g.id === row.id)) {
          this.editedCourse.groupings = this.editedCourse.groupings.filter(
            (r) => r.id !== row.id
          );
        }
      }
    },
    addPayingGrouping(row) {
      this.editedCourse.payingGroupings = [
        ...this.editedCourse.payingGroupings,
        row,
      ];
      if (!this.showAvailableGroupsAndUsers && this.showPayingGroupsAndUsers) {
        if (!this.editedCourse.groupings.some((g) => g.id === row.id)) {
          this.editedCourse.groupings = [...this.editedCourse.groupings, row];
        }
      }
    },

    removeUser(row) {
      if (this.editedCourse.payingUsers.find((pg) => pg.id === row.id)) {
        if (
          !confirm(
            "Du er ved at fjerne en betalende bruger fra kurset. Vil du fortsætte?"
          )
        ) {
          return false;
        }
      }
      this.editedCourse.users = this.editedCourse.users.filter(
        (r) => r.id !== row.id
      );
      this.removePayingUser(row);
    },
    addUser(row) {
      this.editedCourse.users = [...this.editedCourse.users, row];
    },
    removePayingUser(row) {
      this.editedCourse.payingUsers = this.editedCourse.payingUsers.filter(
        (r) => r.id !== row.id
      );
      if (!this.showAvailableGroupsAndUsers && this.showPayingGroupsAndUsers) {
        if (this.editedCourse.users.some((g) => g.id === row.id)) {
          this.editedCourse.users = this.editedCourse.users.filter(
            (r) => r.id !== row.id
          );
        }
      }
    },
    addPayingUser(row) {
      this.editedCourse.payingUsers = [...this.editedCourse.payingUsers, row];
      if (!this.showAvailableGroupsAndUsers && this.showPayingGroupsAndUsers) {
        if (!this.editedCourse.users.some((g) => g.id === row.id)) {
          this.editedCourse.users = [...this.editedCourse.users, row];
        }
      }
    },

    isInt(value) {
      return (
        !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10))
      );
    },
    getPriorityVal(val) {
      if (val === "" || val === null) {
        return null;
      }

      var intVal = parseInt(val);
      if (intVal === 0) {
        return null;
      }

      return intVal;
    },
    async save() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      const basicOnly =
        this.showAvailableGroupsAndUsers && !this.showPayingGroupsAndUsers;
      const payingOnly =
        !this.showAvailableGroupsAndUsers && this.showPayingGroupsAndUsers;
      const noActors =
        !this.showAvailableGroupsAndUsers && !this.showPayingGroupsAndUsers;

      let groupings = this.editedCourse.groupings.map((x) => x.id);
      let users = this.editedCourse.users.map((x) => x.id);
      let payingGroupings = this.editedCourse.payingGroupings.map(
        (pGrp) => pGrp.id
      );
      let payingUsers = this.editedCourse.payingUsers.map((pUsr) => pUsr.id);

      if (basicOnly) {
        // If paying actors aren't relevant, don't send payings.
        payingGroupings = [];
        payingUsers = [];
      } else if (payingOnly) {
        // If paying actors are the only relevant,
        // ensure basics are identical with paying.
        groupings = [...payingGroupings];
        users = [...payingUsers];
      } else if (noActors) {
        // If neither actors are relevant, send nothing.
        groupings = [];
        users = [];
        payingGroupings = [];
        payingUsers = [];
      }

      const course = {
        id: this.editedCourse.id,
        name: this.editedCourse.name,
        description: this.editedCourse.description,
        isFree: this.editedCourse.isFree,
        isPublic: this.editedCourse.isPublic,
        isPublished: this.editedCourse.isPublished,
        priority: this.getPriorityVal(this.editedCourse.priority),
        startDate: this.editedCourse.startDate,
        videoInterval: parseInt(this.editedCourse.videoInterval),

        exerciseIds: this.editedCourse.exercises.map((x) => x.id),
        groupingIds: groupings,
        userIds: users,
        payingGroupingsIds: payingGroupings,
        payingUsersIds: payingUsers,
        tenantId: this.editedCourse.tenantId,
      };
      // console.log(course);
      if (this.isNewCourse) {
        // Create Course
        try {
          await this.$store.dispatch("createCourse", course);
        } catch (error) {
          console.error(error);
        }
      } else {
        // Edit Course
        const obj = {
          id: this.editedCourse.id,
          course: course,
        };
        try {
          await this.$store.dispatch("updateCourse", obj);
        } catch (error) {
          console.error(error);
        }
      }
      this.$emit("saved", course);
    },
    editUser(item) {
      this.editedUser = Object.assign({}, item);
      this.userDialog = true;
    },
    editVideo(item) {
      if (item.isCourseMaterial) {
        this.editedVideo = Object.assign({}, item);
        this.videoDialog = true;
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
.numberInput >>> input::-webkit-inner-spin-button,
.numberInput >>> input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.saveBtnContainer {
  background: transparent;
  color: #738ea6;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 65px;
  padding: 12px 15px;
  z-index: 2;
  float: right;
}

.content-container {
  border: 1px solid black;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
  padding-bottom: 0;
}

.content-container:first-child {
  margin-top: 0px;
}

.content-container:not(:first-child) {
  margin-top: 55px;
}

.content-count-root {
  color: #738ea6;
  display: flex;
  flex-direction: row;
  line-height: 1.2rem;
}

.content-count-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  border-bottom: 2px solid #738ea6;
  width: 35px;
  margin-right: 5px;
}

.content-count-text-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  border: 2px solid transparent;
}

.move-btns-root {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.move-btns-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-field-right >>> input {
  text-align: end;
}

.title-container {
  color: white;
  border: none;
  border-radius: 0;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
}

.description-container {
  padding: 0;
  padding-right: 40px;
}

.description-container >>> fieldset {
  border-color: black;
}

.move-arrow {
  cursor: pointer;
  height: 30px;
  width: 35px;
}

.move-arrow-rotated {
  transform: rotate(180deg);
}

.simple-list-container {
  flex: 1 1 auto;
  height: 300px;
  overflow: scroll;
}

.simple-list-container::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.course-availability-row {
  justify-content: center;
}

.course-availability-header {
  font-weight: bold;
}

.course-availability-content-container {
  display: flex;
  align-items: center;
}

.course-availability-text-container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.course-availability-text {
  text-align: center;
  margin-bottom: 0;
}

.course-availability-text-left {
  width: 110px;
}

.course-availability-text-right {
  width: 135px;
}

.course-availability-switch-container {
  display: flex;
  justify-content: center;
  width: 90px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
