import { render, staticRenderFns } from "./OnlineCourses.vue?vue&type=template&id=66fc1ca1&scoped=true"
import script from "./OnlineCourses.vue?vue&type=script&lang=js"
export * from "./OnlineCourses.vue?vue&type=script&lang=js"
import style0 from "./OnlineCourses.vue?vue&type=style&index=0&id=66fc1ca1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66fc1ca1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})
