import {
  CreateCourseCommand,
  UpdateCourseCommand,
} from "@/models/commands/CourseCommands";
import axios from "axios";
import { Module } from "vuex";
import { RootStore } from "..";

interface Exercise {
  id: number;
  name: string;
}

interface Grouping {
  description: string;
  hasPaid: number;
  id: number;
  name: string;
}

interface User {
  hasPaid: number;
  id: number;
  name: string;
}

interface Course {
  created: Date;
  description: string;
  excerciseCount: number;
  exercises: Exercise[];
  groupingCount: number;
  groupings: Grouping[];
  id: number;
  isFree: boolean;
  isPublic: boolean;
  isPublished: boolean;
  name: string;
  payingGroupingsIds: number[];
  payingUsersIds: number[];
  startDate: Date;
  users: User[];
  userCount: number;
  videoInterval: number;
  priority: number;
  dateDeleted: Date | undefined;
  tenant?: string;
}

interface CoursesState {
  courses: Course[];
}

const coursesModule: Module<CoursesState, RootStore> = {
  state: {
    courses: [],
  },
  mutations: {
    updateCourse(state, obj) {
      Object.assign(state.courses[obj.Idx], obj.updatedCategory);
    },
    setCourses(state, courses: Course[]) {
      state.courses = courses;
    },
  },
  getters: {
    getCourseByItem: (state) => (item: Course) => {
      const index = state.courses.indexOf(item);
      return index;
    },
  },
  actions: {
    async createCourse(
      { commit, rootGetters, dispatch },
      obj: CreateCourseCommand
    ) {
      await dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };

        return new Promise((resolve, reject) => {
          axios
            .post("/api/course", obj, config)
            .then((response) => {
              if (response.status === 200) {
                commit("setAlertSuccessSnack", "Online kurset blev oprettet.");
                resolve(true);
              } else {
                commit("setAlertErrorSnack", "Oprettelsen fejlede.");
                reject(false);
              }
            })
            .catch((err) => {
              if (err.response?.status === 401) {
                commit("setAlertErrorSnack", err);
                reject(false);
              }
            });
        });
      });
    },
    async deleteCourse({ commit, rootGetters, dispatch }, courseId) {
      await dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };

        return new Promise((resolve, reject) => {
          axios
            .delete("api/course/" + courseId, config)
            .then((response) => {
              if (response.status === 200) {
                commit("setAlertSuccessSnack", "Online kurset blev slettet.");
                resolve(true);
              } else {
                commit("setAlertErrorSnack", "Sletning fejlede.");
                reject(false);
              }
            })
            .catch((err) => {
              if (err.response?.status === 401) {
                commit("setAlertErrorSnack", err);
                reject(false);
              }
            });
        });
      });
    },
    async getAllCourses({ commit, rootGetters, dispatch }) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.get("/api/course/all", config);
      response.data.forEach((course: Course) => {
        course.groupingCount = course.groupings.length;
      });
      commit("setCourses", response.data);
    },
    async getCourseExercises(
      { commit, rootGetters, dispatch },
      courseId: number
    ) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.get(
        "/api/course/exercises/" + courseId,
        config
      );
      return response.data;
    },
    async getCourseUsers({ commit, rootGetters, dispatch }, courseId: number) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.get("/api/course/users/" + courseId, config);
      return response.data;
    },
    async getUserCourses({ commit, rootGetters, dispatch }, userId: number) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.get("api/course/user/" + userId, config);
      return response.data;
    },
    async updateCourse(
      { commit, rootGetters, dispatch },
      obj: { id: number; course: UpdateCourseCommand }
    ) {
      await dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };

        return new Promise((resolve, reject) => {
          axios
            .put("/api/course/" + obj.id, obj.course, config)
            .then((response) => {
              if (response.status === 200) {
                commit("setAlertSuccessSnack", "Online kurset blev opdateret.");
                resolve(true);
              } else {
                commit("setAlertErrorSnack", "Opdatering fejlede");
                reject(false);
              }
            })
            .catch((err) => {
              if (err.response?.status === 401) {
                commit("setAlertErrorSnack", err);
                reject(false);
              }
            });
        });
      });
    },
    async updatePriority({ commit, rootGetters, dispatch }, item) {
      console.log(item);
      await dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };

        return new Promise((resolve, reject) => {
          axios
            .put(
              `api/course/updatePriority/${item.id}/${item.priority}`,
              {},
              config
            )
            .then((response) => {
              if (response.status === 200) {
                commit("setAlertSuccessSnack", "Prioriteten er opdateret");
                resolve(true);
              } else {
                commit(
                  "setAlertErrorSnack",
                  "Opdatering af prioritet fejlede."
                );
                reject(false);
              }
            })
            .catch((err) => {
              if (err.response?.status === 401) {
                commit("setAlertErrorSnack", err);
                reject(false);
              }
            });
        });
      });
    },
  },
};

export default coursesModule;
