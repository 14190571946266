import LoginV2Model from "@/models/LoginV2";
import SubscriptionModel from "@/models/Subscription";
import UserModel from "@/models/UserModel";
import axios from "axios";
import moment from "moment";
import { Module } from "vuex";
import { RootStore } from "..";

interface AuthState {
  subscription?: SubscriptionModel;
  user?: UserModel;
  token?: string;
  expiration?: number;
}

const authModule: Module<AuthState, RootStore> = {
  state: {
    subscription: undefined,
    user: undefined,
    token: undefined,
    expiration: undefined,
  },
  mutations: {
    setLoggedIn(state, loginObj: LoginV2Model) {
      const base64 = loginObj.token.split(".")[1];
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      const jwt_obj = JSON.parse(jsonPayload);
      const expiration: number = jwt_obj.exp;

      if (loginObj.token && jwt_obj.exp) {
        const timestampNow = moment.utc().unix();
        if (expiration > timestampNow) {
          localStorage.setItem("jwt", JSON.stringify(loginObj));
          state.token = loginObj.token;
          state.expiration = expiration;
          state.user = loginObj.user;
          state.subscription = loginObj.subscription;
          return true;
        } else {
          console.log("Token has expired.");
          return false;
        }
      }
      return false;
    },
    setLogout(state) {
      state.token = undefined;
      state.user = undefined;
      state.expiration = undefined;
      localStorage.removeItem("jwt");
    },
    SetLoggedInUser(state, userData: UserModel) {
      state.user = userData;

      const token = JSON.parse(localStorage.getItem("jwt")!) as LoginV2Model;
      token.user = userData;
      localStorage.setItem("jwt", JSON.stringify(token));
    },
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    getJWTFromStorage(): LoginV2Model | undefined {
      const authItem = localStorage.getItem("jwt");
      if (authItem) {
        const model = JSON.parse(authItem) as LoginV2Model;
        if (model && model.token && model.user) {
          return model;
        }
      }
      return undefined;
    },
    getJWT(state) {
      if (state.token) {
        return state.token;
      }
      return null;
    },
  },
  actions: {
    async configureIsLoggedIn({ commit, getters, dispatch }) {
      console.log("Attempting autologin");
      const loginObj = getters.getJWTFromStorage as LoginV2Model | undefined;
      if (loginObj) {
        commit("setLoggedIn", loginObj);
        if (!getters.isLoggedIn) {
          await dispatch("refreshJWT", loginObj.token);
        }
      }
      return getters.isLoggedIn;
    },
    async login({ commit }, obj: any) {
      try {
        const response = await axios.post("/api/login/LoginV2", {
          Email: obj.Email,
          Password: obj.Password,
        });
        if (response.status === 200) {
          commit("setAlertSuccessSnack", "Logget ind!");
          commit("setLoggedIn", response.data);
          obj.router.push({
            path: "/Home" + (obj.NewUser ? "?newUser" : ""),
          });
          return;
        }
        throw new Error("Invalid response: " + response.status);
      } catch (error) {
        commit(
          "setAlertErrorSnack",
          "kode eller password forkert - prøv igen."
        );
        throw error;
      }
    },
    logout({ commit }) {
      console.log("Logging out!");
      axios
        .get("/api/login/logout")
        .then((response) => commit("setLogout", response.data));
    },
    async refreshJWT({ state, commit, dispatch }, token: string) {
      if (state.token && state.expiration) {
        const timestampNow = moment.utc().unix();
        if (state.expiration > timestampNow) {
          return "Token Considered Valid.";
        }
      }

      try {
        const response = await axios.post("/api/login/refresh", {
          ExpiredJWT: token,
        });
        console.log(response);
        commit("setLoggedIn", response.data);
        return response.data;
      } catch (error: any) {
        console.log("refresh failed, logging out.");
        dispatch("logout");
      }
    },
  },
};

export default authModule;
