import axios, { AxiosResponse } from "axios";
import moment from "moment";
import { Module } from "vuex";
import { RootStore } from "..";

interface MeState {
  me: {
    id: number;
    name: string;
    email: string;
    role: string;
    created: string;
    updated: string;
    birthDate?: string;
    gender: string;
    height: number;
    weight: number;
    onTrial: false;
    trialEnd?: string;
    verifiedAt?: string;
  };
  isSubscribed: boolean;
  superAdmin: boolean;
  nextScreeningDate?: string;
}

const meModule: Module<MeState, RootStore> = {
  state: {
    me: {
      //default state.
      name: "",
      email: "",
      role: "Freemium",
      created: "",
      updated: "",
      gender: "Other",
      height: 0,
      weight: 0,
      onTrial: false,
      id: -1,
      trialEnd: undefined,
      verifiedAt: undefined,
    },
    isSubscribed: false,
    superAdmin: false,
  },
  mutations: {
    setMe(state, obj) {
      console.log("set me called: ");

      obj.user.birthDate = obj.user.birthDate
        ? moment(obj.user.birthDate).format("YYYY-MM-DD")
        : null;

      state.me = obj.user;
      state.isSubscribed = obj.subscription;
      state.nextScreeningDate = obj.nextScreeningDate;
      state.superAdmin = obj.superAdmin;
      console.log(state.me);
    },
    updateMe(state, obj) {
      state.me = obj;
    },
  },
  getters: {
    getMe(state) {
      return state.me;
    },
  },
  actions: {
    async GetMyUserInformation({ commit, rootGetters, dispatch }) {
      await dispatch("refreshJWT", rootGetters.getJWT);

      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };

      const response = await axios.get("/api/users/me", config);

      commit("setMe", response.data);
      commit("SetLoggedInUser", response.data.user);
    },
    async GetMeObj({ commit, rootGetters, dispatch }) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.get("/api/users/me", config);
      return response.data;
    },
    async UpdateMyUserInformation({ commit, rootGetters, dispatch }, obj) {
      obj.updated = moment.utc().unix();

      obj.trialEnd = new Date(obj.trialEnd).getTime() / 1000;
      obj.height = obj.height ? parseInt(obj.height) : 0;
      obj.weight = obj.weight ? parseInt(obj.weight) : 0;

      dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
            "Content-Type": "application/json",
          },
        };
        const data = JSON.stringify(obj);
        console.log(data);
        axios.put("/api/users/me", data, config).then((response) => {
          commit("setMe", {
            user: response.data.newUser,
          });
          commit("setAlertSuccessSnack", "User updated successfully");
        });
      });
    },
    async UpdateMyUserPassword({ commit, rootGetters, dispatch }, obj) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
          "Content-Type": "application/json",
        },
      };
      const data = JSON.stringify(obj);
      console.log(data);
      try {
        await axios.put("/api/users/updateMyPassword", data, config);
        commit("setAlertSuccessSnack", "Dit kodeord er skiftet");
      } catch (error: any) {
        const err = error.response as AxiosResponse;
        commit(
          "setAlertErrorSnack",
          err.status === 403
            ? "Du har indtastet forkert nuværende kodeord"
            : "Kunne ikke skifte kodeord"
        );
        throw error;
      }
    },
    async getNewScreening({ commit, rootGetters, dispatch }) {
      commit("setLoading", true);
      await dispatch("refreshJWT", rootGetters.getJWT);

      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.put(
        "/api/users/GetNewScreening",
        {},
        config
      );
      if (response.status == 200) {
        commit("setAlertSuccessSnack", "Ny screening åbnes...");
        window.location = response.data;
      } else {
        commit(
          "setAlertErrorSnack",
          "En ny screening kunne ikke oprettes, prøv igen senere"
        );
      }
      commit("setLoading", false);
    },
    async skipScreening({ commit, rootGetters, dispatch }) {
      commit("setLoading", true);
      dispatch("refreshJWT", rootGetters.getJWT)
        .then(() => {
          const config = {
            headers: {
              Authorization: "Bearer " + rootGetters.getJWT,
              "Content-Type": "application/json",
            },
          };
          axios.put("/api/users/SkipScreening", {}, config).then((response) => {
            if (response.status != 200) {
              commit(
                "setAlertErrorSnack",
                "Der opstod en fejl og din screening kunne ikke blive udsat. Prøv igen næste gang du bliver spurgt"
              );
            }
          });
        })
        .finally(() => commit("setLoading", false));
    },
    async SendEmailVerification({ commit, rootGetters, dispatch }) {
      commit("setLoading", true);
      try {
        await dispatch("refreshJWT", rootGetters.getJWT);
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
            "Content-Type": "application/json",
          },
        };
        const response = await axios.post(
          "/api/users/email-verification",
          {},
          config
        );
        if (response.status != 200) {
          commit(
            "setAlertErrorSnack",
            "Der opstod en fejl og din screening kunne ikke blive udsat. Prøv igen næste gang du bliver spurgt"
          );
        } else {
          commit("setAlertSuccessSnack", "Email er sendt af sted.");
        }
      } catch (error) {
        commit("setLoading", false);
      }
    },
  },
};

export default meModule;
