var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loggedInUser.role == 'Admin')?_c('div',{staticClass:"subscriptions d-flex justify-center",staticStyle:{"padding":"10px","padding-top":"20px"}},[_c('v-card',{attrs:{"loading":_vm.loading,"width":"100vw","min-width":"300px"}},[_c('v-card-title',{staticStyle:{"padding-top":"0px","padding-bottom":"0px"}},[_c('v-container',{staticStyle:{"margin":"10px","max-width":"100%","padding-top":"0px","padding-bottom":"0px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',[_vm._v(" Abonnementer ")]),_c('v-row',[_c('v-switch',{attrs:{"label":"Vis kun Aktive abonnenter"},model:{value:(_vm.showOnlyActiveSubs),callback:function ($$v) {_vm.showOnlyActiveSubs=$$v},expression:"showOnlyActiveSubs"}})],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søg i Abonnementer","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.subscriptionHeaders,"items":_vm.filteredSubscriptionList,"footer-props":{ 'items-per-page-options': [5, 15, 25, 35, 99] },"items-per-page":99,"search":_vm.search,"expanded":_vm.expanded,"show-group-by":false},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.expandRow},scopedSlots:_vm._u([{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.created)))]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.updated)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasOneSubscriptionActive(item) == true)?_c('v-btn',{attrs:{"text":"","small":"","color":"error"},on:{"click":function($event){return _vm.CancelSubscription(item)}}},[_vm._v("Afbryd Abonnement")]):_vm._e()]}},{key:"item.open",fn:function(ref){return [_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-arrow-expand-vertical")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status.status == 'Active')?[(_vm.isActiveTrial(item.status))?_c('span',{staticClass:"text-start",staticStyle:{"color":"#a61bc4","font-weight":"bold"}},[_vm._v(" Aktiv trial ")]):_c('span',{staticClass:"text-start",staticStyle:{"color":"#77bc27","font-weight":"bold"}},[_vm._v(" Aktivt ("+_vm._s(_vm.formatDate(item.nextChargeDate))+")")])]:(_vm.isPendingCancelled(item.status))?_c('span',{staticStyle:{"color":"#dd8800"}},[_vm._v("Udløber "+_vm._s(_vm.formatDate(item.nextChargeDate)))]):(_vm.isFullyCancelled(item.status))?_c('span',{staticStyle:{"color":"#ff5b6c"}},[_vm._v("Aflyst")]):(item.status.status == 'Pending')?_c('span',{staticStyle:{"color":"#e2cb2e"}},[_vm._v("Afventer abonnement")]):_vm._e()]}},{key:"item.nextChargeDate",fn:function(ref){
var item = ref.item;
return [(item.nextChargeDate == null)?_c('span',[_vm._v(" - ")]):(_vm.isPendingCancelled(item))?_c('span',{staticStyle:{"color":"#dd8800"}},[_vm._v("Opsagt")]):(_vm.isFullyCancelled(item))?_c('span',{staticStyle:{"color":"#ff5b6c"}},[_vm._v("Opsagt")]):_c('span',[_vm._v(_vm._s(_vm.formatDate(item.nextChargeDate)))])]}},{key:"item.subscriptionFee",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.subscriptionFee)+",-")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticStyle:{"margin":"10px"},attrs:{"flat":"","width":"100%"}},[_c('v-card-title',[_vm._v(" Faktura'er for abonnement: "+_vm._s(item.id)+" ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.invoiceHeaders,"items":_vm.sortedInvoiceList,"footer-props":{
                    'items-per-page-options': [5, 15, 25, 35, 99],
                  },"items-per-page":25},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [(item.status == 'Pending')?_c('span',{staticStyle:{"color":"#e2cb2e"}},[_vm._v("Afventer")]):_vm._e(),(item.status == 'Success')?_c('span',{staticStyle:{"color":"#77bc27"}},[_vm._v("Gennemført")]):_vm._e(),(item.status == 'Failed')?_c('span',{staticStyle:{"color":"#ff5b6c"}},[_vm._v("Fejlet")]):_vm._e(),(item.status == 'Cancelled')?_c('span',{staticStyle:{"color":"#dd8800"}},[_vm._v("Anulleret")]):_vm._e()]}},{key:"item.created",fn:function(ref){
                  var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.created)))]}},{key:"item.processed",fn:function(ref){
                  var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.processed)))]}},{key:"item.feeExcludingVat",fn:function(ref){
                  var item = ref.item;
return [_vm._v(_vm._s(item.feeExcludingVat)+",-")]}},{key:"item.feeTaken",fn:function(ref){
                  var item = ref.item;
return [_vm._v(_vm._s(item.feeTaken)+",-")]}}],null,true)})],1)],1)],1)]}}],null,false,4232063336)})],1),_c('v-card-actions')],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }