import axios from "axios";
import { Module } from "vuex";
import { RootStore } from "..";

interface Course {
  id: number;
  name: string;
}

interface Grouping {
  courses: Course[];
  description: string;
  id: number;
  name: string;
  tenant?: string;
}

interface GroupingState {
  groupings: Grouping[];
}

const groupingsModule: Module<GroupingState, RootStore> = {
  state: {
    groupings: [],
  },
  mutations: {
    setGroupings(state, groupings: Grouping[]) {
      state.groupings = groupings;
    },
  },
  getters: {
    getGroupingByItem: (state) => (item: Grouping) => {
      const index = state.groupings.indexOf(item);
      return index;
    },
  },
  actions: {
    async createGrouping({ commit, rootGetters, dispatch }, obj) {
      await dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };

        return new Promise((resolve, reject) => {
          axios
            .post("/api/groupings", obj, config)
            .then((response) => {
              if (response.status === 200) {
                commit("setAlertSuccessSnack", "Gruppe oprettet.");
                resolve(true);
              } else {
                commit("setAlertErrorSnack", "Oprettelsen fejlede");
                reject(false);
              }
            })
            .catch((err) => {
              if (err.response?.status === 401) {
                commit("setAlertErrorSnack", err);
                reject(false);
              }
            });
        });
      });
    },
    async deleteGrouping({ commit, rootGetters, dispatch }, groupingId) {
      await dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };

        return new Promise((resolve, reject) => {
          axios
            .delete("/api/groupings/" + groupingId, config)
            .then((response) => {
              if (response.status === 200) {
                commit("setAlertSuccessSnack", "Gruppen blev slettet.");
                resolve(true);
              } else {
                commit("setAlertErrorSnack", "Sletningen fejlede.");
                reject(false);
              }
            })
            .catch((err) => {
              if (err.response?.status === 401) {
                commit("setAlertErrorSnack", err);
                reject(false);
              }
            });
        });
      });
    },
    async duplicateGrouping({ commit, rootGetters, dispatch }, obj) {
      commit("setLoading", true);
      await dispatch("createGrouping", {
        description: obj.description,
        name: obj.name + " - Copy",
        courseIds: obj.courseIds,
        userIds: obj.userIds,
      });
    },
    async getAllGroupings({ commit, rootGetters, dispatch }) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.get("api/groupings/all", config);
      commit("setGroupings", response.data);
    },
    async getGroupingCourses({ commit, rootGetters, dispatch }, groupingId) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.get(
        "/api/groupings/" + groupingId + "/courses",
        config
      );
      return response.data;
    },
    async getGroupingUsers({ commit, rootGetters, dispatch }, groupingId) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.get(
        "api/groupings/" + groupingId + "/users",
        config
      );
      return response.data;
    },
    async getUserGroupings({ commit, rootGetters, dispatch }, userId) {
      await dispatch("refreshJWT", rootGetters.getJWT);
      const config = {
        headers: {
          Authorization: "Bearer " + rootGetters.getJWT,
        },
      };
      const response = await axios.get("api/groupings/user/" + userId, config);
      return response.data;
    },
    async updateGrouping({ commit, rootGetters, dispatch }, obj) {
      await dispatch("refreshJWT", rootGetters.getJWT).then(() => {
        const config = {
          headers: {
            Authorization: "Bearer " + rootGetters.getJWT,
          },
        };

        return new Promise((resolve, reject) => {
          axios
            .put(
              "/api/groupings/" + obj.updatedGrouping.id,
              obj.updatedGrouping,
              config
            )
            .then((response) => {
              if (response.status === 200) {
                commit("setAlertSuccessSnack", "Gruppen blev opdateret.");
                resolve(true);
              } else {
                commit("setAlertErrorSnack", "Opdateringen fejlede.");
                reject(false);
              }
            })
            .catch((err) => {
              console.log(err);
              if (err.response?.status === 401) {
                commit("setAlertErrorSnack", err);
                reject(false);
              }
            });
        });
      });
    },
  },
};

export default groupingsModule;
